<template>
<div>
  <Form :model="formItem" :label-width="100" ref="signupForm">
    <Row style="min-height: 90vh">
      <Col span="12" style="border-right: 1px solid #999">
        <div style="margin-bottom: 30px;font-weight: bold">基本信息</div>
        <FormItem label="问卷名称">
          <Input v-model="formItem.paper_name" style="width: 60%"></Input>
        </FormItem>
        <FormItem label="封面图片">
          <Upload
              name="img"
              ref="upload"
              :show-upload-list="false"
              :default-file-list="defaultList"
              :format="['jpg','jpeg','png']"
              :on-format-error="handleFormatError"
              :before-upload="handleBeforeUpload"
              action="#"
              style="display: inline-block;width:58px;">
            <div style="width: 58px;height:58px;line-height: 58px;text-align: center;background-color: #fff">
              <Icon type="ios-camera" size="20"></Icon>
            </div>
          </Upload>
        </FormItem>
        <FormItem>
          <Row :gutter="10">
            <Col span="6" v-for="(item,index) in defaultList" :key="index" style="position:relative;">
              <div @click="delAttach()" style="position: absolute;right: -5px;top:-5px;width: 20px;height: 20px;background-color: #515a6e;border-radius: 50%;color: #fff;line-height: 20px;text-align: center">X</div>
              <img :src="item.url" alt="" style="width: 100%;height: auto">
            </Col>
          </Row>
        </FormItem>

<!--        <FormItem label="报名期限">-->
<!--          <DatePicker clearable type="daterange" split-panels placeholder="出具日期" v-model="dateRange" size="large" @on-clear="dateRange = []"></DatePicker>-->
<!--        </FormItem>-->

        <FormItem label="问卷简介">
          <Input type="textarea" :rows="4" v-model="formItem.paper_intro" style="width: 60%" size="large"></Input>
        </FormItem>

        <FormItem>
          <Button size="large" type="primary" @click="submit()" :disabled="btnDisabled">确定</Button>
        </FormItem>

      </Col>
      <Col span="12" style="padding-left: 30px">
        <div style="margin-bottom: 30px;font-weight: bold">问卷表单</div>
        <div style="margin-bottom: 15px" v-for="(item, index) in formItem.paper_form" :key="index">
          <template v-if="item.paper_form_type === 'text'">
            <div style="display: flex;">
              <div style="width: 80%;padding: 10px;border:1px solid #999;">
                <!--                <div>{{item.signup_form_name}}:</div>-->
                <Input v-model="item.paper_form_name" style="width: 80%"></Input>：
<!--                <Input style="width: 600px;margin-top: 5px" v-model="item.paper_form_default" ></Input>-->
                <Input style="width: 600px;margin-top: 5px"></Input>
              </div>
              <div style="flex: 1;padding: 10px;display: flex;justify-content: space-around;align-items: center;flex-direction: column;border:1px solid #999">
                <InputNumber size="small" :max="10" :min="1" v-model="item.paper_form_order" @on-blur="changeFormItemOrder"></InputNumber>
                <Button size="small" type="error" @click="delFormItem(index)">删除</Button>
              </div>
            </div>

          </template>
          <template v-else-if="item.paper_form_type === 'textarea'">
            <div style="display: flex;">
              <div style="width: 80%;padding: 10px;border:1px solid #999">
                <Input v-model="item.paper_form_name" style="width: 80%"></Input>：
<!--                <Input type="textarea" :rows="4" v-model="item.paper_form_default" style="width: 600px;margin-top: 5px"></Input>-->
                <Input type="textarea" :rows="4" style="width: 600px;margin-top: 5px"></Input>
              </div>
              <div style="flex: 1;padding: 10px;display: flex;justify-content: space-around;align-items: center;flex-direction: column;border:1px solid #999">
                <InputNumber size="small" :max="10" :min="1" v-model="item.paper_form_order" @on-blur="changeFormItemOrder"></InputNumber>
                <Button size="small" type="error" @click="delFormItem(index)">删除</Button>
              </div>
            </div>

          </template>
          <template v-else-if="item.paper_form_type === 'radio'">
            <div style="display: flex;">
              <div style="width: 80%;padding: 10px;border:1px solid #999">
                <Input v-model="item.paper_form_name" style="width: 80%"></Input>：
<!--                <RadioGroup v-model="item.paper_form_default" style="width: 600px;margin-top: 5px" >-->
<!--                  <Radio :label="x" v-for="(x, y) in item.paper_form_options" :key="'radio'+y"></Radio>-->
<!--                </RadioGroup>-->
                <RadioGroup style="width: 600px;margin-top: 5px" >
                  <Radio :label="x" v-for="(x, y) in item.paper_form_options" :key="'radio'+y"></Radio>
                </RadioGroup>
              </div>
              <div style="flex: 1;padding: 10px;display: flex;justify-content: space-around;align-items: center;flex-direction: column;border:1px solid #999">
                <InputNumber size="small" :max="10" :min="1" v-model="item.paper_form_order" @on-blur="changeFormItemOrder"></InputNumber>
                <Button size="small" type="error" @click="delFormItem(index)">删除</Button>
              </div>
            </div>
          </template>
          <template v-else-if="item.paper_form_type === 'checkbox'">
            <div style="display: flex;">
              <div style="width: 80%;padding: 10px;border:1px solid #999">
                <Input v-model="item.paper_form_name" style="width: 80%"></Input>：
<!--                <Select v-model="item.paper_form_default" style="width:600px;margin-top: 5px">-->
<!--                  <Option :value="x" v-for="(x, y) in item.signup_form_options" :key="'select'+y">{{x}}</Option>-->
<!--                </Select>-->
                <Select style="width:600px;margin-top: 5px" multiple>
                  <Option :value="x" v-for="(x, y) in item.paper_form_options" :key="'select'+y">{{x}}</Option>
                </Select>
              </div>
              <div style="flex: 1;padding: 10px;display: flex;justify-content: space-around;align-items: center;flex-direction: column;border:1px solid #999">
                <InputNumber size="small" :max="10" :min="1" v-model="item.paper_form_order" @on-blur="changeFormItemOrder"></InputNumber>
                <Button size="small" type="error" @click="delFormItem(index)">删除</Button>
              </div>
            </div>
          </template>

          <template v-else-if="item.paper_form_type === 'date'">
            <div style="display: flex;">
              <div style="width: 80%;padding: 10px;border:1px solid #999">
                <Input v-model="item.paper_form_name" style="width: 80%"></Input>：
                <DatePicker type="date" placeholder="Select date" style="width: 200px;margin-top: 5px"></DatePicker>
              </div>
              <div style="flex: 1;padding: 10px;display: flex;justify-content: space-around;align-items: center;flex-direction: column;border:1px solid #999">
                <InputNumber size="small" :max="10" :min="1" v-model="item.paper_form_order" @on-blur="changeFormItemOrder"></InputNumber>
                <Button size="small" type="error" @click="delFormItem(index)">删除</Button>
              </div>
            </div>
          </template>

          <template v-else-if="item.paper_form_type === 'upload'">
            <div style="display: flex;">
              <div style="width: 80%;padding: 10px;border:1px solid #999">
                <Input v-model="item.paper_form_name" style="width: 80%"></Input>：
                <Input style="width: 200px;margin-top: 5px" placeholder="上传图片"></Input>
              </div>
              <div style="flex: 1;padding: 10px;display: flex;justify-content: space-around;align-items: center;flex-direction: column;border:1px solid #999">
                <InputNumber size="small" :max="10" :min="1" v-model="item.paper_form_order" @on-blur="changeFormItemOrder"></InputNumber>
                <Button size="small" type="error" @click="delFormItem(index)">删除</Button>
              </div>
            </div>
          </template>

        </div>

        <div style="margin-top: 20px"><Button type="primary" @click="callAddColModal()">添加一个表单字段</Button></div>
      </Col>
    </Row>
  </Form>

  <Modal v-model="modal" @on-ok="addFormItems()" width="1000" :loading="loading">
    <div >
      类型：
      <Select v-model="form.paper_form_type" style="width:200px" size="large">
        <Option value="text" >文本框</Option>
        <Option value="textarea" >长文本</Option>
        <Option value="radio" >单选</Option>
        <Option value="checkbox" >多选</Option>
        <Option value="date" >日期</Option>
        <Option value="upload" >上传</Option>
      </Select>
    </div>
    <div style="margin-top: 10px">
      名称：
      <Input v-model="form.paper_form_name" style="width: 200px"/>
    </div>
    <div style="margin-top: 10px">
      排序：
      <InputNumber :min="1" v-model="form.paper_form_order"></InputNumber>
    </div>
<!--    <template v-if="form.paper_form_type === 'text' || form.paper_form_type === 'textarea' || form.paper_form_type === 'radio' || form.paper_form_type === 'checkbox'">-->
<!--      <div style="margin-top: 10px">-->
<!--        默认值：-->
<!--        <Input v-model="form.paper_form_default" style="width: 200px" placeholder="默认值必须包含在选项中,否则无效"/>-->
<!--      </div>-->
<!--    </template>-->


    <template v-if="form.paper_form_type === 'radio' || form.paper_form_type === 'checkbox'">
      <div style="margin-top: 10px">
        选项：
        <Input v-model="option" @on-enter="addOption()" style="width: 350px" placeholder="按回车键生成一个选项"/>
        <div style="margin-top: 10px;margin-left: 40px">
          <Tag v-for="(item, index) in form.paper_form_options" :key="item" :name="item" closable @on-close="delOption(index)" style="height: auto">{{ item }}</Tag>
        </div>
      </div>
    </template>

  </Modal>
</div>
</template>

<script>
import lrz from "lrz";
import api from '../../api/xiaochengxu';
// import filter from '../../filter';
export default {
  name: "AddPaper",
  data: function(){
    return {
      formItem: {
        paper_name: '',
        paper_img: '',
        // paper_begin_time: '',
        // paper_end_time: '',
        paper_intro: '',
        paper_form: []
      },
      defaultList:[],
      // dateRange: [],
      modal: false,
      loading: true,
      form: {
        paper_form_name: '',
        paper_form_type: '',
        paper_form_default: '',
        paper_form_options: [],
        paper_form_order: 0
      },
      option: ''
    }
  },
  watch: {
    // dateRange: {
    //   handler: function(){
    //     this.formItem.paper_begin_time = '';
    //     this.formItem.paper_end_time = '';
    //     if(this.dateRange[1]) this.dateRange[1].setDate(this.dateRange[1].getDate())
    //     this.formItem.paper_begin_time = filter.formatDate2(this.dateRange[0]);
    //     this.formItem.paper_end_time = filter.formatDate2(this.dateRange[1]);
    //   },
    //   immediate: false
    // }
  },
  computed:{
    btnDisabled: function(){
      return this.formItem.paper_name === '' ||
          this.formItem.paper_img === '' ||
          // this.formItem.paper_end_time === '' ||
          this.formItem.paper_intro === '' ||
          this.formItem.paper_form.length < 1
    }
  },
  methods: {
    delOption: function(index){
      this.form.paper_form_options.splice(index, 1)
    },
    submit: function(){
      this.$http.post(api.addPaper, this.formItem).then(response => {
        if(response.data.success) {
          this.$Message.success('添加成功');
          this.$router.replace('/xiaochengxu/paper')
        } else {
          this.$Message.error(response.data.err);
          console.log(response);
        }
      })
    },
    changeFormItemOrder: function(){
      this.formItem.paper_form.sort(function(a, b) {
        return a.paper_form_order - b.paper_form_order
      })
    },
    delFormItem: function(index){
      this.formItem.paper_form.splice(index, 1)
    },
    addFormItems: function(){
      if(this.form.paper_form_type && this.form.paper_form_name) {
        this.formItem.paper_form.push(this.form);
        this.formItem.paper_form.sort(function(a, b) {
          return a.paper_form_order - b.paper_form_order
        })
        this.form = {
          paper_form_name: '',
          paper_form_type: '',
          paper_form_default: '',
          paper_form_options: [],
          paper_form_order: 0
        };
        this.loading = false
        this.modal = false;
      } else {
        this.loading = false
        this.$Message.error('类型和名称是必填项');
      }
    },
    addOption: function(){
      this.form.paper_form_options.push(this.option);
      this.option = ''
    },
    callAddColModal: function(){
      this.modal = true;
    },
    handleFormatError: function(){
      this.$Message.error('请上传图片格式的文件。');
    },
    delAttach: function(){
      this.formItem.paper_img = ''
      this.defaultList = [];
    },
    handleBeforeUpload (file) {
      const that = this;
      lrz(file,{fieldName: 'file'}).then(res =>{
        that.$http.post(api.upload, res.formData).then(response => {
          if(response.data.success) {
            this.formItem.paper_img = response.data.path;
            this.defaultList = [];
            this.defaultList.push({
              name: response.data.path,
              url: response.data.path
            })
          } else {
            this.$Message.error(response.data.err);
            console.log(response);
          }
        })
      })
    },
  },
  mounted() {
    this.$emit('lastPage', '/xiaochengxu/paper')
  },
  destroyed() {
    this.$emit('lastPage', '')
  }
}
</script>

<style scoped>

</style>